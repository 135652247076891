export const DEV_DOMAIN = "dev.bimgx.com"; // 开发环境主域名
export const PROD_DOMAIN = "bimgx.com"; // 正事环境主域名

export const enum SERVER_EXCEPTION_CODE {
    BAD_REQUEST = 400,
    FORBIDDEN = 403,
    UPGRADE_REQUIRED = 426,
    TOO_MANY_REQUESTS = 429,
    WRONG_REQUEST = 491,
    INTERNAL_SERVER_ERROR = 500,
    BIZ_LOGIC = 591,
}