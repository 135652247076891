
import { defineComponent } from "vue";
import { api } from "@/common/api";
import { ElMessage } from "element-plus";

export default defineComponent({
    name: "GeneralConfig",
    data() {
        return {
            config: {
                guestMode: true,
                enableWXStickerExport: false,
                hotWordList: [""],
            },
            hotWordList: "",
        };
    },

    async mounted() {
        await this.loadConfig();
    },

    methods: {
        async loadConfig() {
            let tRes = await api.get("/config/get-config");
            this.config = tRes.data;
            this.hotWordList = this.config.hotWordList.join("\n");
        },

        async onSubmit() {
            this.config.hotWordList = this.hotWordList.split("\n");
            let tRes = await api.post("/config/set-config", this.config);
            ElMessage({
                message: "修改成功",
                type: "success",
            });
        },
    },
});
